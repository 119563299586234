<template>
  <div class="dingtalk">
    <h3>钉钉- 新质生产力工具</h3>
    <p>在钉钉强大的组织、沟通、协同、业务和生态在线的基座上助力企业打造专属、安全、开放的数字化办公运营平台。</p>
    <div class="_scroll_container">
      <ul>
        <li v-for="(item, index) in list">
          <label><img :src="item.img" /></label>
          <div>
            <div class="dd-title">{{ item.name }}</div>
            <div class="dd-remark">{{ item.desc }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="btn-list">
      <a :href="onLine" target="_blank" v-if="$route.path != '/cloud/dingtalk/professional'">立即咨询</a>
      <a :href="onLine" target="_blank" :style="{ flex: 1, width: 'auto', margin: 'auto', color: '#fff' }"
        v-else>立即咨询了解更多详情</a>
      <button @click="$router.push('/cloud/dingtalk/professional')" v-if="$route.path != '/cloud/dingtalk/professional'">了解详情</button>
    </div>
  </div>
</template>
<script>
import { onLine } from "@/data.ts";
export default {
  data() {
    return {
      onLine,
      list: [
        {
          name: "钉钉专业版",
          img: "./static/img/dd-icon1.png",
          desc: "满足中小企业数字化升级需求，打造灵活开放的数字化生产力平台，解决6大组织数字化难题，让企业管理更加简单。"
        },
        {
          name: "钉钉专属版",
          img: "./static/img/dd-icon2.png",
          desc: "构建企业专属的办公协同APP，在钉钉强大的组织、沟通、协同、业务和生态在线的底座上，助力企业打造专属、安全、开放的数字化办公运营平台。"
        },
        {
          name: "AI助理",
          img: "./static/img/dd-icon3.png",
          desc: "汇集钉钉AI产品能力，帮助企业迈入智能新时代。集成智能文档、智能会议、智能应用等多项AI能力，覆盖众多办公场景。"
        },
        {
          name: "TEAMBITION",
          img: "./static/img/dd-icon4.png",
          desc: "一套聪明好用的项目管理工具，包含项目、待办、网盘、文档、日历等丰富应用，显著提升企业内部协作效率，使用起来爱不释手。"
        },
        {
          name: "钉钉低代码",
          img: "./static/img/dd-icon5.png",
          desc: "云钉原生低代码平台，帮助企业以可视化的方式，快速构建出企业专属应用，降低数字化门槛，提升各类应用的开发效率，缩减开发成本。"
        },
      ]
    }
  }
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}

.dingtalk {
  padding: 60px 20px;
  background: #150f33 url(../../assets/index/home_dd_back.jpg) no-repeat bottom;
  background-size: cover;

  >h3 {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }

  >p {
    font-size: 15px;
    color: #fff;
    line-height: 2;
    font-weight: 300;
    margin-top: 25px;
    text-align: justify;
  }

  ._scroll_container {
    overflow-x: auto;
    margin: 0 -20px;
    margin-top: 30px;

    >ul {
      display: flex;

      &::after {
        content: '';
        display: block;
        width: 20px;
        min-width: 20px;
      }

      >li {
        margin: 0;
        margin-left: 20px;
        width: 244px;
        min-width: 244px;
        background-color: #0082fe;
        padding: 30px 20px;
        box-sizing: border-box;
        border-radius: 10px;


        >label {
          width: 57px;
          height: 57px;
          display: block;
          box-sizing: border-box;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }

        .dd-title {
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          line-height: 1.8;
          text-align: center;
          margin-top: 15px;
          margin-bottom: 20px;
        }

        .dd-remark {
          font-size: 15px;
          font-weight: 400;
          color: #fff;
          line-height: 2;
          text-align: justify;
        }
      }
    }
  }


  .btn-list {
    display: flex;
    margin-top: 40px;

    >button,>a {
      text-align: center;
      display: inline-block;
      width: 50%;
      margin: 0 10px;
      border: 0 none;
      background: none;
      appearance: none;
      border-radius: 3px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        background-color: #E80B10;
        color: #fff;
      }

      &:last-child {
        border: 1px solid #E80B10;
        color: #E80B10;
      }
    }
  }
}
</style>
