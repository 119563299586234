<template>
  <div class="cloud">
    <div class="cloud-item">
      <h3>阿里云区域能力中心</h3>
      <p>阿里云精英级服务商<br/>百城计划城市服务商<br/>大数据与AI生态合作伙伴</p>
    </div>
    <div class="cloud-item">
      <h3>钉钉全国新锐服务商</h3>
      <p>专属钉钉区域核心代理<br/>TEAMBITION授权服务中心<br/>钉钉宜搭战略核代</p>
    </div>
  </div>
</template>
<style lang="less" scoped>
.cloud{
 background-color: #fff;
 display: flex;
 justify-content:space-between;
 padding: 15px 20px;
 .cloud-item{
  color: #383838;
  >img{
    height: 34px;
    display: block;
    margin: auto;
  }
  >h3{
    font-size: 15px;
    font-weight: bold;
    line-height: 1.8;
    display: flex;
    align-items: center;
    &::before{
      content: '';
      height: 5px;
      width: 5px;
      display: block;
      background-color: #F23D49;
      margin-right: 10px;
      transform: rotate(45deg);
    }
  }
  >p{
    padding-left: 15px;
    font-size: 12.5px;
    font-weight: 300;
    line-height: 1.5;
  }
 }
}
</style>
