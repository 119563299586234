<template>
  <div class="cloud-item">
    <div class="cloud-item-head">{{ title }}</div>
    <div class="cloud-item-desc">{{ desc }}</div>
    <div class="cloud-item-body clearfix">
      <div class="_scroll_wapper">
        <div class="_scroll_wapper_item" v-for="(item, index) in list">
          <div class="header">{{ item.name }}<a :href="onLine" target="_blank">立即咨询</a></div>
          <div class="_img_box">
            <img :src="item.img" />
          </div>
          <p>{{ item.desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { onLine } from "@/data";
import Item from './Item.vue';
export default {
  components: {
    Item
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}

.cloud-item {
  overflow: hidden;
  padding: 30px 0 40px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .cloud-item-head {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #383838;
    margin-bottom: 10px;
  }

  .cloud-item-desc {
    font-size: 14px;
    text-align: center;
    color: #383838;
    margin-bottom: 25px;
  }

  .cloud-item-body {
    overflow: auto;

    ._scroll_wapper {
      display: flex;

      &::after {
        content: '';
        display: block;
        width: 15px;
        min-width: 15px;
        height: 100px;
      }

      ._scroll_wapper_item {
        margin-left: 15px;
        padding: 20px 15px;
        background-color: white;
        border-radius: 10px;
        width: 226px;
        min-width: 226px;
        text-align: center;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;

          a {
            text-align: center;
            display: inline-block;
            border: 1px solid #dbdbdb;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            width: 75px;
            padding: 0;
            appearance: none;
            background-color: white;
            font-weight: 400;
            color: #383838;
          }
        }

        ._img_box {
          display: inline-block;
          height: 90px;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 25px auto 20px;
        }

        img {
          display: block;
        }

        p {
          text-align: justify;
          font-size: 15px;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>
