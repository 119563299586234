
import SolutionItem from './SolutionItem_v2.vue'
import router from '@/router';
import { pc_origin } from "@/data";
export default {
  components: {
    SolutionItem
  },
  data() {
    return {
      list: [
        { img: '/static/img/j4.jpg', name: '通义千问AI大模型解决方案', to: '/solution-ai.html' },
        { img: '/static/img/j2.jpg', name: '全场景沟通协作解决方案', to: '/solution-all.html' },
        { img: '/static/img/j3.jpg', name: '高创数智云解决方案', to: '/solution/synergy' },
      ]
    }
  },
  methods: {
    goDetail(to: String) {
      if (to.indexOf(".html") > -1) {
        window.open(pc_origin + to);
      } else {
        router.push(`${to}`);
      }
    }
  }
}
