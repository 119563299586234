
import { onLine } from "@/data";
export default {
  props: {
    item: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return { onLine }
  }
}
