
import { onLine, pc_origin } from "@/data";
declare let Swiper: any;
export default {
  data() {
    return { onLine }
  },
  computed: {
    aAll: () => {
      return pc_origin + "/solution-all.html"
    }
  },
  mounted() {
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 5000,//1秒切换一次
      },
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },
    })
  }
}
