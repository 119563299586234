<template>
  <div class="solutions">
    <h3>解决方案 · solutions</h3>
    <p>三大解决方案帮助企业挖掘信息化价值的发现与创新，助力企业数字化转型。</p>
    <div class="solutions-list">
      <SolutionItem v-for="(item, index) in list" :key="index" :item="item" @detail="goDetail" />
    </div>
  </div>
</template>
<script lang="ts">
import SolutionItem from './SolutionItem_v2.vue'
import router from '@/router';
import { pc_origin } from "@/data";
export default {
  components: {
    SolutionItem
  },
  data() {
    return {
      list: [
        { img: '/static/img/j4.jpg', name: '通义千问AI大模型解决方案', to: '/solution-ai.html' },
        { img: '/static/img/j2.jpg', name: '全场景沟通协作解决方案', to: '/solution-all.html' },
        { img: '/static/img/j3.jpg', name: '高创数智云解决方案', to: '/solution/synergy' },
      ]
    }
  },
  methods: {
    goDetail(to: String) {
      if (to.indexOf(".html") > -1) {
        window.open(pc_origin + to);
      } else {
        router.push(`${to}`);
      }
    }
  }
}
</script>
<style lang="less" scoped>
.solutions {
  background-color: white;
  padding: 50px 10px 45px;

  >h3 {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #383838;
    text-transform: uppercase;
  }

  >p {
    font-size: 15px;
    color: #383838;
    line-height: 2;
    font-weight: 300;
    margin-top: 25px;
    padding: 0 15px;
    text-align: center;
  }

  & &-list {
    margin-top: 35px;
  }
}
</style>
