<template>
  <div class="cloud-container">
    <!-- <Coupons/> -->
    <!-- <CloudItemBox title="阿里云热销产品 · HOT SALE" desc="官方渠道购买  /  专家顾问服务  /  更多优惠折扣" :list="list" /> -->
    <CloudItemBox title="阿里云 - AI时代的共同选择" desc="企业的全能AI助手" :list="list" />
  </div>
</template>
<script lang="ts">
import CloudItemBox from './CloudItemBox_v2.vue';
import Coupons from './Coupons.vue';
export default {
  components: {
    CloudItemBox,
    Coupons
  },
  data() {
    return {
      // list: [
      //   { title: '弹性计算', name: '云服务器 ECS', remark: '从安全型到内存型、从进阶型到入门型的云服务器', price: '￥1992.00/6月起' },
      //   { title: '数据库', name: '云数据库 POLARDB', remark: '阿里巴巴自主研发的下一代关系型分布式云原生数据库', price: '￥2937.60/年起' },
      //   { title: '存储服务', name: '对象存储 OSS', remark: '适合图片/音视频等多媒体数据存储，数据实时处理，海量存储无上限', price: '￥99.00/年起' },
      //   { title: '云通信', name: '短信服务', remark: '支持国内验证码、短信通知和推广短信，秒级触达，免运维', price: '￥0.036/条起' },
      //   { title: '云安全', name: 'WEB应用防火墙', remark: '防注入，防CC，一站式WEB安全解决方案', price: '￥3880.00/月起' },
      //   { title: '大数据', name: 'DATAV数据可视化', remark: '通过图形化的界面轻松搭建专业水准的可视化应用', price: '￥475.00/月起' },
      // ]
      list: [
        {
          name: "通义听悟",
          img: "./static/img/icon-1.png",
          desc: "聚焦音视频内容高效提炼的工作学习AI助手，为每一个人提供音视频记录与阅读的全新体验。"
        },
        {
          name: "通义灵码",
          img: "./static/img/icon-2.png",
          desc: "提供行/函数级续写、单元测试生成、注释生成、代码解释、研发问答等功能。"
        },
        {
          name: "通义星尘",
          img: "./static/img/icon-3.png",
          desc: "在简单的设定下，提供拟人化、场景化、多模态的对话能力，支持情感陪伴、游戏NPC等场景。"
        },
        {
          name: "通义晓蜜",
          img: "./static/img/icon-4.png",
          desc: "通过智能对话平台、全渠道联络中心、智能坐席助理，助力企业服务向智能化、多模态转型。"
        },
        {
          name: "通义点金",
          img: "./static/img/icon-5.png",
          desc: "金融大模型驱动的智能投研助手，轻松分析金融事件，深度解读财报研报，自动绘制表格图表。"
        },
        {
          name: "通义法睿",
          img: "./static/img/icon-6.png",
          desc: "拥有法律的理解推理能力，能够回答法律问题、推送裁判类案、辅助案情分析、生成法律文书。"
        },
        {
          name: "通义仁心",
          img: "./static/img/icon-7.png",
          desc: "针对疾病、症状、药品、报告指标等医疗健康方面的问题提供专业、精准、有效的解答。"
        },
        {
          name: "通义智文",
          img: "./static/img/icon-8.png",
          desc: "通义智文包含文档问答、文档智能导读、个人文档库、阅读笔记等功能，辅助用户高效阅读。"
        }
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.cloud-container {
  position: relative;
  background-color: #f7f7f7;

  &::after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    bottom: 30px;
    background: linear-gradient(0deg, #284CB9, #9A0000);
    z-index: -1;
  }
}
</style>
